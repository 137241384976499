.card {
  display: flex;
  flex-direction: row;
  background: $color-shades-white;
  color: $color-shades-black;
  border: 1px solid $color-shades-darkGray;
  border-radius: 0.25rem;
  overflow: hidden;
  transition: .2s all;

  &__img {
    display: none;
    width: 10rem;
    height: 10rem;
    @include TP {
      display: block;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    gap: 1rem;
    @include TP {
      padding: 1.5rem 2rem;
    }

    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &-details {
      display: flex;
      flex-direction: column;
      color: $color-shades-lightBlack;
      gap: 0.5rem;
      @include TP {
        flex-direction: row;
        gap: 2rem;
      }

      .meta {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $color-shades-black;
        min-height: 1.5rem;
        gap: 0.5rem;
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 0.5rem;
    border-left: 1px solid $color-shades-lightGray;
    transition: .2s all;
    @include TP {
      padding: 1rem;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &:hover {
    box-shadow: 0 0.25rem 0 transparentize($color-shades-black, 0.8);
    
    .card__link {
      background-color: $color-hues-green;
    }
  }
}